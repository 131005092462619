import { Router } from '@angular/router';
import { of, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, withLatestFrom } from 'rxjs/operators';

import * as baseActions from '@actions/base.actions';
import * as actions from '../actions/user-data.actions';
import * as selectors from '../selectors/user-data.selectors';
import * as mfaModalActions from '@actions/mfa-modal.actions';

import { STORAGE } from '@const/storage';
import { EnvConfig } from '@models/env-config';
import { UserAuthData } from '@models/user-auth-data';
import { UserDataState } from '@reducers/user-data.reducer';
import { MfaModalState } from '@reducers/mfa-modal.reducer';
import { UserData, UserDataAPI } from '@models/user-profile';
import { JwtService } from '@services/jwt.service/jwt.service';
import { STEPS } from '@components/mfa-modal/constants/mfa-const';
import { StorageService } from '@services/storage.service/storage.service';
import { UserDataService } from '@services/user-data.service/user-data.service';
import { EnvConfigService } from '@services/env-config.service/env-config.service';
import { ApplicationInsightsService } from '@services/application-insights.service/application-insights.service';

@Injectable()
export class UserDataEffects {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly jwtService: JwtService,
    private readonly store: Store<UserDataState>,
    private readonly storageService: StorageService,
    private readonly userDataService: UserDataService,
    private readonly envConfigService: EnvConfigService,
    private readonly mfaModalStore: Store<MfaModalState>,
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {}

  getAuthCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAuthCode),
      switchMap(({ userName, identityProvider }) =>
        this.userDataService.getAuthCode$({ userName, identityProvider })
          .pipe(
            map(() => actions.getAuthCodeSuccess()),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.getAuthCodeFailure({ error }))),
          )),
    ),
  );

  getUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUserProfile),
      switchMap(() =>
        this.userDataService.getUserProfile$()
          .pipe(
            map(userProfileData => actions.getUserProfileSuccess({ userProfileData })),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.getUserProfileFailure({ error }))),
          )),
    ),
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUserProfile),
      switchMap(({ userProfileData }) =>
        this.userDataService.updateUserProfile$(userProfileData)
          .pipe(
            map(() => actions.updateUserProfileSuccess({ userProfileData })),
            tap(() => this.mfaModalStore.dispatch(mfaModalActions.setStep({ step: STEPS.success }))),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.updateUserProfileFailure({ error }))),
          )),
    ),
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changePassword),
      switchMap(({ data }) =>
        this.userDataService.changePassword$(data)
          .pipe(
            map(() => actions.changePasswordSuccess()),
            tap(() => this.mfaModalStore.dispatch(mfaModalActions.setStep({ step: STEPS.success }))),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.changePasswordFailure({ error }))),
          )),
    ),
  );

  changePhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changePhone),
      switchMap(({ data }) =>
        this.userDataService.changePhone$(data)
          .pipe(
            map(() => actions.changePhoneSuccess({ data })),
            tap(() => this.mfaModalStore.dispatch(mfaModalActions.setStep({ step: STEPS.success }))),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.changePhoneFailure({ error }))),
          )),
    ),
  );

  changeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeEmail),
      switchMap(({ data }) =>
        this.userDataService.changeEmail$(data)
          .pipe(
            map(() => actions.changeEmailSuccess({ email: data.email })),
            tap(() => this.mfaModalStore.dispatch(mfaModalActions.setStep({ step: STEPS.success }))),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.changeEmailFailure({ error }))),
          )),
    ),
  );

  syncPhysicianWithNpi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.syncPhysicianWithNpi),
      switchMap(() =>
        this.userDataService.syncPhysicianWithNpi$()
          .pipe(
            map(data => actions.syncPhysicianWithNpiSuccess({ data })),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.syncPhysicianWithNpiFailure({ error }))),
          )),
    ),
  );

  getToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getToken),
      withLatestFrom(this.store.pipe(select(selectors.selectUserAuthData))),
      switchMap(([{ isRedirect }, userAuthData]) =>
        this.userDataService.getToken$(UserAuthData.getBodyForGetToken(userAuthData))
          .pipe(
            tap(data => this.storageService.set(STORAGE.accessToken, data.accessToken)),
            tap(data => this.storageService.set(STORAGE.refreshToken, data.refreshToken)),
            tap(data => {
              const userData: UserData = UserData.getDataFromAPI(JSON.parse(JSON.stringify(this.jwtService.decodeToken(data.accessToken))) as UserDataAPI);

              this.userDataService.setUserData(userData);
              this.storageService.set(STORAGE.userData, userData);
              this.applicationInsightsService.setUserContext(userData.sub, `${userAuthData.userName + '::' + userData.emailAddress}`);
            }),
            switchMap(() => this.envConfigService.getEnvConfig$()),
            map((config: EnvConfig) => {
              this.envConfigService.setNewEnvConfig(config);

              isRedirect && this.router
                .navigate([this.storageService.get(STORAGE.redirectUrl) || ''], { queryParams: this.storageService.get(STORAGE.redirectUrlQuery) || null })
                .finally(() => {
                  this.storageService.remove(STORAGE.redirectUrlQuery);
                  this.store.dispatch(actions.getTokenSuccess({ pending: false }));
                });

              return actions.getTokenSuccess({ pending: !!isRedirect });
            }),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.getTokenFailure({ error }))),
          )),
    ),
  );
}
